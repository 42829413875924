@font-face {
    font-family: 'Sage Text';
    src: url('fonts/Sage_Text-Bold.woff') format('woff');
    src: url('fonts/Sage_Text-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sage Text';
    src: url('fonts/Sage_Text-Bold_Italic.woff') format('woff');
    src: url('fonts/Sage_Text-Bold_Italic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Sage Text';
    src: url('fonts/Sage_Text-Regular.woff') format('woff');
    src: url('fonts/Sage_Text-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sage Text';
    src: url('fonts/Sage_Text-Bold_Italic.woff') format('woff');
    src: url('fonts/Sage_Text-Bold_Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'opensans-regular';
    src: url('fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'adellesanssage';
    src: url('fonts/AdelleSansSage.ttf') format('truetype');
}

@font-face {
    font-family: 'FontAwesome';
    src: url('fonts/fa-solid-900.eot'); /* IE9 Compat Modes */
    src: url('fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/fa-solid-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/fa-solid-900.woff') format('woff'), /* Pretty Modern Browsers */
    url('fonts/fa-solid-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/fa-solid-900.svg#svgFontName') format('svg'); /* Legacy iOS */
}

.card-columns {
    display: -webkit-flex;
    -webkit-flex-direction: row;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

    .card-columns .card {
        margin-left: 20px;
        width: 100%;
    }

@media (min-width: 1000px) {
    .card-columns .card {
        width: 31%;
    }
}

@media (min-width: 576px) {
    .card-columns .card {
        width: 31%;
    }
}

@media (min-width: 20em) {
    .card-columns .card {
        width: 90%;
    }

    .carousel-caption {
        left: 14%;
        padding-bottom: 23%;
    }

    .div-carousel {
        min-height: 83px;
    }
}

@media (min-width: 34em) {
    .card-columns .card {
        width: 45%;
    }

    .carousel-caption {
        left: 14%;
        padding-bottom: 24%;
    }

    .div-carousel {
        min-height: 118px;
    }
}

@media (min-width: 48em) {
    .card-columns .card {
        width: 31%;
    }

    .carousel-caption {
        left: 13%;
        padding-bottom: 29%;
    }

    .div-carousel {
        min-height: 127px;
    }

    .carousel-indicators {
        bottom: 0;
    }
}

@media (min-width: 56em) {


    .carousel-caption {
        left: 13%;
        padding-bottom: 29%;
    }

    .div-carousel {
        min-height: 127px;
    }

    .carousel-indicators {
        bottom: 0;
    }
}


@media (min-width: 75em) {

    .carousel-caption {
        left: 15%;
        padding-bottom: 29%;
    }

    .div-carousel {
        min-height: 203px;
    }

    .carousel-indicators {
        bottom: 0;
    }
}

@-webkit-keyframes color-change {
    0% {
        color: red;
    }

    50% {
        color: #f2f2f2;
    }

    100% {
        color: red;
    }
}

@-moz-keyframes color-change {
    0% {
        color: red;
    }

    50% {
        color: #f2f2f2;
    }

    100% {
        color: red;
    }
}

@-ms-keyframes color-change {
    0% {
        color: red;
    }

    50% {
        color: #f2f2f2;
    }

    100% {
        color: red;
    }
}

@-o-keyframes color-change {
    0% {
        color: red;
    }

    50% {
        color: #f2f2f2;
    }

    100% {
        color: red;
    }
}

@keyframes color-change {
    0% {
        color: red;
    }

    50% {
        color: #f2f2f2;
    }

    100% {
        color: red;
    }
}

@keyframes animate-row {
    0% {
        background-position: -500px 0;
        opacity: 0;
    }

    100% {
        background-position: 0 0;
        opacity: 1;
    }
}

h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: normal;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.88em;
    margin-block-end: 0.88em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: normal;
}

body {
    font-family: "Sage Text", Arial, sans-serif;    
}

.container {
    padding-top: 10px;
}

.card {
    border: 0;
    border-radius: 0;
}

.card-body {
    height: 257px;
    background-color: #f2f2f2;
    overflow-y: auto;
    padding-bottom: 0;
}

.card-columns .card:first-child {
    margin-bottom: 10px;
}

.card-footer {
    text-align: center;
    background-color: #f2f2f2;
    border-top: 0;
}

.btn, .btn:hover, .btn:focus, .btn:active, .btn:visited {
    -webkit-box-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-family: "Sage Text", Arial, sans-serif;
    font-weight: 500;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 1.4em;
    letter-spacing: normal;
    margin: 0px;
    outline: none;
    overflow: visible;
    position: relative;
    text-align: center;
    text-decoration: none;    
    user-select: none;
    padding: 0px 24px;
    border-radius: 48px;
    height: 48px;
    min-width: 110px;
    background-color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 16px;
    width: auto;
    cursor: pointer;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:visited {    
    background-color: rgb(0, 0, 0) !important;
    border-color: rgb(0, 0, 0) !important;
    color: rgb(255, 255, 255) !important;
    outline: none !important;
    width: 90%;
}

    .btn-primary:hover {
        background-color: #333 !important;
        transition: background-color 0.15s linear 0s, border 0.15s linear 0s, color 0.15s linear 0s, box-shadow 0.15s linear 0s !important;
    }

.card .card-promoted {
    z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
}

.card ul {
    list-style: none;
    padding: 0;
}

.card li {
    margin-left: 20px;
}

    .card li:before {
        content: url('images/check.svg');
        display: inline-block;
        margin-left: -1.3em;
        margin-right: 20px;
        zoom: 25%;
    }


.card .card-new {
    color: red;
    font-weight: bold;
    white-space: nowrap;
    font-size: small;
    -webkit-animation: color-change 3s infinite;
    -moz-animation: color-change 3s infinite;
    -o-animation: color-change 3s infinite;
    -ms-animation: color-change 3s infinite;
    animation: color-change 3s infinite;
}

.nav.nav-tabs .container {
    text-align: center;
}

.nav-tabs {
    border-bottom: 1px solid #999999;
    width: 100%;
    margin-bottom: 0.83em;
}

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        border: 0;
        border-bottom: 4px solid #00A310;
        font-weight: bold;
        margin-bottom: -1px;
        transform: none;
    }

li.nav-item {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: -7px !important;
    cursor: pointer;
}

.nav-link:hover:not(.active), .nav-link:focus:not(.active) {
    text-decoration: none;
    /* border: 0px !important; */
    border-color: transparent !important;
    animation-name: BlackToGrey;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes BlackToGrey {
    from {
        color: black;
    }

    to {
        color: grey;
    }
}

li.nav-item:hover {
    border: 0;
}

.embed-responsive .card-img-top {
    display: block;
    max-width: 100%;
    height: auto;
}

.modal-full {
    min-width: 90vh;
    margin: 0 auto;
    border: 0;
    border-radius: 0;
}

    .modal-full .modal-content {
        height: 90vh;
        /* overflow-y: auto; */
        min-height: calc(100% - 100px) !important;
    }

        .modal-full .modal-content .modal-body {
            overflow-y: auto;
        }


.modal.show {
    overflow-y: hidden;
}

    .modal.show .modal-dialog {
        top: 50%;
        transform: translateY(-50%);
    }


.modal-footer {
    display: block;
}

.card-image__skeleton {
    background-color: #f7f7f7;
}

.card__skeleton {
    display: block;
    background: linear-gradient(to right, #bbb, #f7f7f7);
    padding-bottom: 10px;
    animation: animate-row 1500ms linear infinite;
}

.pointer {
    cursor: pointer;
}

.carousel.slide {
    width: 100% !important;
}

.carousel-item > img {
    width: 100%;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    cursor: pointer;
}

.carousel-caption {
    color: #f8f8f8;
    text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
}

.div-carousel {
    text-align: center;
    margin-bottom: 15px;
}

.carousel-caption.d-none.d-md-block {
    display: inline !important;
    padding: 20px;
}

.categories-select {
    min-width: 150px;
}

@media (min-width: 500px) {
    .card-columns .card {
        width: 45%;
    }
}

@media (min-width: 770px) {
    .card-columns .card {
        width: 30%;
    }
}

@media (min-width: 1000px) {
    .card-columns .card {
        width: 31%;
    }
}
